<template>
  <div class="analysis-list" v-loading.fullscreen.lock="loading" :element-loading-text="$t('common.preparingData')">
    <ai-breadcrumb />
    <div class="new-button">
      <guide class="guide-button" />
      <el-button class="guide-button" size="small" icon="el-icon-guide" type="info" @click="handleReportManual">{{
    $t("common.reportManual") }}</el-button>
      <router-link :to="'/services/analysis/tps/generate'">
        <el-button icon="el-icon-plus" size="small" type="primary">{{
    $t("analysis.generateReport")
  }}</el-button>
      </router-link>
    </div>
    <div class="page-tab">
      <el-tabs v-model="mode" type="card" @tab-click="changeMode">
        <el-tab-pane :label="$t('analysis.group')" name="group">
        </el-tab-pane>
        <el-tab-pane :label="$t('common.brand') + ' & ' + $t('common.item')" name="detail">
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="search-content">
      <el-form :inline="true" size="small" class="search-form">
        <el-form-item class="search-form-gutter" :label="$t('analysis.platform')">
          <el-select style="width: 88px" v-model="currentPlatform" @change="changePlatform" filterable>
            <el-option v-for="item in platformList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="mode == 'group'" class="search-form-gutter" :label="$t('analysis.group') + '1'">
          <el-select style="width: 118px" v-model="previousGroupFristDates" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <label class="date-vs">VS</label>
          <el-select style="width: 118px" v-model="previousGroupSecondDates" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="mode == 'detail'" class="search-form-gutter" :label="$t('analysis.date')">
          <el-select style="width: 118px" v-model="previousGroupFristDates" clearable @change="changeDates">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <label class="date-vs">VS</label>
          <el-select style="width: 118px" v-model="previousGroupSecondDates" clearable @change="changeDates">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="mode == 'detail'" class="search-form-gutter" :label="$t('common.brand')">
          <el-select style="width: 298px" v-model="currentBrands" multiple clearable>
            <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="mode == 'group'" class="search-form-gutter" :label="$t('analysis.group') + '2'">
          <el-select style="width: 118px" v-model="currentGroupFristDates" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <label class="date-vs">VS</label>
          <el-select style="width: 118px" v-model="currentGroupSecondDates" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="mode == 'detail'" class="search-form-gutter" :label="$t('common.item')">
          <el-select style="width: 298px" v-model="currentItems" multiple clearable>
            <el-option v-for="item in itemList" :key="item.value" :label="item.label" :value="item.value"
              :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" circle @click="fetchData"></el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-loading="tpsPreviewLoading" class="tps-previews">
      <el-row :gutter="10">
        <el-col :span="8" v-for="t in primaryTpsList" :key="t.item">
          <el-card shadow="never">
            <div slot="header" class="">
              <span>{{ t.item }}</span>
            </div>
            <div>
              <el-table cell-class-name="primary-cell" :show-header="false" :data="t.details" row-key="specificItems">
                <el-table-column prop="specificItems"> </el-table-column>
                <el-table-column width="70" class-name="primary-image-cell">
                  <template slot-scope="scope">
                    <router-link target="_blank" :to="{
    path: '/services/analysis/style-search',
    query: { q: scope.row.key, platform: currentPlatform },
  }">
                      <el-image class="primary-image-content" :src="scope.row.url" fit="contain" />
                    </router-link>
                  </template>
                </el-table-column>
                <el-table-column min-width="55">
                  <template slot-scope="scope">
                    {{ scope.row.fristAvg.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column min-width="55">
                  <template slot-scope="scope">
                    {{ scope.row.secondAvg.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column min-width="28">
                  <template slot-scope="scope">
                    <i v-if="scope.row.trend > 0" class="trend-col el-icon-top-right" />
                    <i v-else-if="scope.row.trend < 0" class="trend-col el-icon-bottom-right" />
                    <i v-else class="trend-col el-icon-right" />
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div class="primary-no-data" v-if="!tpsPreviewLoading && (!primaryTpsList || primaryTpsList.length === 0)
    ">
        <span>No Preview Data</span>
      </div>
    </div>
    <el-table :data="tableData" row-key="id" style="width: 100%">
      <el-table-column v-if="mode == 'group'" prop="previousGroupFristLinkDates" key="previousGroupFristLinkDates"
        :label="$t('analysis.group') + '1-1'"></el-table-column>
      <el-table-column v-if="mode == 'group'" prop="previousGroupSecondLinkDates" key="previousGroupSecondLinkDates"
        :label="$t('analysis.group') + '1-2'"></el-table-column>
      <el-table-column v-if="mode == 'group'" prop="currentGroupFristLinkDates" key="currentGroupFristLinkDates"
        :label="$t('analysis.group') + '2-1'"></el-table-column>
      <el-table-column v-if="mode == 'group'" prop="currentGroupSecondLinkDates" key="currentGroupSecondLinkDates"
        :label="$t('analysis.group') + '2-2'"></el-table-column>
      <el-table-column v-if="mode == 'detail'" prop="previousGroupFristLinkDates" key="previousGroupFristLinkDates"
        :label="$t('analysis.date') + '1'"></el-table-column>
      <el-table-column v-if="mode == 'detail'" prop="previousGroupSecondLinkDates" key="previousGroupSecondLinkDates"
        :label="$t('analysis.date') + '2'"></el-table-column>
      <el-table-column v-if="mode == 'detail'" prop="brandNames" key="brandNames"
        :label="$t('common.brand')"></el-table-column>
      <el-table-column v-if="mode == 'detail'" prop="items" key="items" :label="$t('common.item')"></el-table-column>
      <el-table-column :label="$t('common.createAt')" min-width="130">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
    scope.row.createdAt | displayTime()
  }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.summaryReport')" min-width="80">
        <template slot-scope="scope">
          <a :href="scope.row.summaryUrl | ossUrl">
            <el-button :disabled="scope.row.summaryUrl === ''" class="excel-dl-btn" icon="el-icon-download"
              size="small">{{ $t("common.download") }}</el-button>
          </a>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.report')" min-width="80">
        <template slot-scope="scope">
          <a :href="scope.row.url | ossUrl">
            <el-button class="excel-dl-btn" icon="el-icon-download" size="small">{{ $t("common.download")
              }}</el-button>
          </a>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.action')" min-width="80">
        <template slot-scope="scope">
          <el-popconfirm :title="$t('analysis.deleteReport')" @confirm="deleteReport(scope.row.id)">
            <el-button type="danger" icon="el-icon-delete" size="small" slot="reference">{{ $t("common.delete")
              }}</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total > 0" :total="total" :offset.sync="listQuery.offset" :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total " @pagination="fetchData" />
  </div>
</template>

<script>
import {
  fetchTPSReports,
  fetchTPSItemReports,
  deleteReport,
  fetchRecords,
  fetchPrimaryTps,
  fetchUserBrands,
  fetchUserItems,
} from "@/api/analysis";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import Pagination from "@/components/Pagination";
import Guide from "@/components/Guide";
import { downloadFile } from "@/utils/download-file";
import { ossRoot } from "@/utils/server";

export default {
  components: { AiBreadcrumb, Pagination, Guide },
  data() {
    return {
      currentPlatform: "tmall",
      platformList: [
        { key: "tmall", value: "TMall" },
        { key: "tiktok", value: "Tiktok" },
      ],
      previousGroupFristDates: null,
      previousGroupSecondDates: null,
      currentGroupFristDates: null,
      currentGroupSecondDates: null,
      currentBrands: [],
      currentItems: [],
      brandList: [],
      itemList: [],
      options: [],
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      loading: false,
      primaryTpsList: [],
      currentBrandImagesIdx: [0, 0, 0],
      tpsPreviewLoading: false,
      mode: "group"
    };
  },
  created() {
    this.$store.dispatch("category/fresh").then(() => {
      this.resetItemList();
      this.fetchData();
      this.fetchOptions();
      this.fetchPrimaryTps();
    });
  },
  methods: {
    resetItemList() {
      let t2Flatten = this.$store.getters.t2Flatten;
      this.itemList = [];
      t2Flatten &&
        t2Flatten.forEach((t) => {
          t.disabled = true;
          this.itemList.push(t);
        });
    },
    fetchOptions() {
      this.previousGroupFristDates = null;
      this.previousGroupSecondDates = null;
      this.currentGroupFristDates = null;
      this.currentGroupSecondDates = null;
      this.currentBrands = [];
      this.currentItems = [];
      this.brandList = [];
      this.itemList = [];
      this.options = [];
      this.tableData = [];
      this.total = 0;
      fetchRecords({
        type: "sales",
        period: "week",
        platform: this.currentPlatform,
        limit: -1,
      }).then((response) => {
        if (response.success) {
          response.result &&
            response.result.items &&
            response.result.items.forEach((e) => {
              this.options.push({
                value: e.date,
                label: e.linkDate,
              });
            });
        }
      });
    },
    fetchData() {
      if (this.mode === 'detail') {
        let params = {
          firstDates: this.previousGroupFristDates,
          secondDates: this.previousGroupSecondDates,
          brands: this.currentBrands,
          items: this.currentItems,
          platform: this.currentPlatform,
          ...this.listQuery,
        }
        fetchTPSItemReports(params).then((response) => {
          if (response.success) {
            this.tableData = response.result.items || [];
            this.total = response.result.totalCount;
          }
        });
      } else {
        let params = {
          previousGroupFristDates: this.previousGroupFristDates,
          previousGroupSecondDates: this.previousGroupSecondDates,
          currentGroupFristDates: this.currentGroupFristDates,
          currentGroupSecondDates: this.currentGroupSecondDates,
          platform: this.currentPlatform,
          ...this.listQuery,
        };
        fetchTPSReports(params).then((response) => {
          if (response.success) {
            this.tableData = response.result.items || [];
            this.total = response.result.totalCount;
          }
        });
      }
    },
    deleteReport(id) {
      deleteReport("TPS", id).then((response) => {
        if (response.success) {
          this.$notify({
            title: this.$t("common.deleteSucc"),
            type: "success",
          });
          this.fetchData();
        }
      });
    },
    handleReportManual() {
      let env = process.env.VUE_APP_ENV;
      if (!env) {
        env = "staging";
      }
      if (env === "production.kr") { env = "production" }
      downloadFile(ossRoot + "/" + env + "/common/TREND MAP_20200723_ALL.pdf");
    },
    fetchPrimaryTps() {
      this.tpsPreviewLoading = true;
      fetchPrimaryTps({ platform: this.currentPlatform })
        .then((response) => {
          if (response.success) {
            this.primaryTpsList = response.result.items || [];
          }
        })
        .finally(() => {
          this.tpsPreviewLoading = false;
        });
    },
    fetchBrands() {
      this.currentBrands = [];
      this.brandList = [];
      fetchUserBrands({
        dates: this.datesString(),
        platform: this.currentPlatform,
      }).then((response) => {
        if (response.success) {
          this.brandList =
            response.result && response.result.items
              ? response.result.items
              : [];
        }
      });
    },
    fetchItems() {
      this.currentItems = [];
      this.resetItemList();
      fetchUserItems({
        dates: this.datesString(),
      }).then((response) => {
        if (response.success && response.result) {
          this.itemList.forEach((e, i) => {
            let disabled = true;
            for (let j = 0; j < response.result.length; j++) {
              const r = response.result[j];
              if (e.value === r) {
                disabled = false;
                break;
              }
            }
            e.disabled = disabled;
            this.$set(this.itemList, i, e);
          });
        }
      });
    },
    clickNext(idx) {
      this.$set(this.currentBrandImagesIdx, idx, 1);
    },
    clickPre(idx) {
      this.$set(this.currentBrandImagesIdx, idx, 0);
    },
    changePlatform() {
      this.fetchData();
      this.fetchOptions();
      this.fetchPrimaryTps();
    },
    changeDates() {
      this.fetchBrands();
      this.fetchItems();
    },
    changeMode() {
      this.previousGroupFristDates = null;
      this.previousGroupSecondDates = null;
      this.currentGroupFristDates = null;
      this.currentGroupSecondDates = null;
      this.currentBrands = [];
      this.currentItems = [];
      this.brandList = [];
      this.itemList = [];
      this.tableData = [];
      this.total = 0;
      this.fetchData();
    },
    datesString() {
      let v = [];
      if (this.previousGroupFristDates) {
        v.push(this.previousGroupFristDates);
      }
      if (this.previousGroupSecondDates) {
        v.push(this.previousGroupSecondDates);
      }
      return v.length > 0 ? v.join(",") : ""
    }
  }
};
</script>

<style lang="scss" scoped>
.analysis-list {
  .el-col-lg-2-4 {
    width: 1/11 * 100%;
  }

  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;

    .guide-button {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .page-tab {
    background-color: white;

    /deep/ .el-tabs__header {
      padding-top: 10px;
      padding-left: 10px;
      margin-bottom: 0;
    }

    /deep/ .el-tabs__item {
      height: 34px;
      line-height: 34px;
    }
  }

  .search-content {
    background-color: #fff;
    padding: 10px 0 0 10px;
    margin-bottom: 10px;

    .el-form-item {
      margin-bottom: 10px;
    }

    .search-form {
      width: auto;

      .search-form-gutter {
        margin-right: 30px;
      }
    }

    .date-vs {
      vertical-align: middle;
      font-size: 14px;
      color: #606266;
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  .tps-previews {
    margin-bottom: 10px;
    min-height: 100px;

    .el-card {
      border: none;
      border-radius: 0;
    }

    /deep/ .el-card__header {
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;
      font-weight: bold;
    }

    /deep/ .el-card__body {
      padding: 0;
    }

    /deep/ .el-table__row td {
      border: none !important;
      padding: 0;
      font-size: 12px;
    }

    /deep/ .primary-cell .cell {
      max-height: 50px;
    }

    /deep/ .primary-image-cell .cell {
      height: 50px !important;
    }

    .primary-image-content {
      height: 50px;
    }

    .trend-col {
      margin-left: -10px;
      font-size: medium;
    }
  }

  .primary-no-data {
    padding-top: 30px;
    text-align: center;

    span {
      color: #909399;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to

  /* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
  }
}
</style>